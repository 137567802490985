import React from 'react'
import PropTypes from 'prop-types'

import './BungeeLogo.css'

const BungeeLogo = ({text})=>{

    return (
        <div>
            <div className={'bungee-logo shade'}><span>{text}</span></div>
            <div className={'bungee-logo background'} role={'presentation'} aria-hidden='true'><span>{text}</span></div>
        </div>
    )
}

BungeeLogo.propTypes = {
    text: PropTypes.string
  }

export default BungeeLogo